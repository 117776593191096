import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.GivingLevelSectionFragment | null
  headingLevel?: 2 | 3
}

export const GivingLevelSection = ({
  data,
  headingLevel = 3,
  ...props
}: Props): JSX.Element => {
  const H1 = `h${headingLevel}` as ElementType
  const H2 = `h${headingLevel + 1}` as ElementType
  const H3 = `h${
    headingLevel + (data?.subheading ? 1 : 2)
  }` as ElementType
  const styles = {
    section: css`
      max-width: 85ch;
      padding: 2em 3em 3em;
      box-sizing: border-box;
      margin-top: 2em;
      margin-bottom: 2.5em;
      [data-giving-levels] + & {
        margin-top: -2.5em;
      }
    `,
    heading: css`
      text-align: center;
      font-size: var(--fs-36);
      font-family: var(--ff-display);
      margin: 0 0 1em;
      ${data?.subheading &&
      css`
        margin-bottom: 0.25em;
      `}
    `,
    subheading: css`
      text-align: center;
      font-size: var(--fs-24);
      font-family: var(--ff-display);
      margin: 0 0 1em;
    `,
    list: css`
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2em 1.5em;
      ${mq().ms} {
        grid-template-columns: 1fr 1fr;
      }
    `,
    listItem: css`
      text-align: center;
      line-height: 1.125;
    `,
    levelTitle: css`
      margin: 0 0 0.5em;
      font-size: 1em;
      font-family: var(--ff-body);
      font-weight: 650;
    `,
    levelAmount: css``,
  }
  return (
    <section
      css={styles.section}
      data-giving-levels
      {...props}
    >
      <H1 css={styles.heading}>{data?.heading}</H1>
      {data?.subheading && (
        <H2 css={styles.subheading}>{data?.subheading}</H2>
      )}
      <ul css={styles.list}>
        {data?.givingLevels?.map((level, i) => (
          <li
            css={styles.listItem}
            key={i}
          >
            <H3 css={styles.levelTitle}>{level?.title}</H3>
            <div css={styles.levelAmount}>{level?.amount}</div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export const GivingLevelSectionFragment = graphql`
  fragment GivingLevelSection on DatoCmsGivingLevelSection {
    __typename
    id: originalId
    heading
    subheading
    givingLevels {
      title
      amount
    }
  }
`
